<template>
    <div>

        <template v-if="!isLogin">

            <TopNav v-model="this.store" @sidebarHandler="sidebarHandler">
            </TopNav>
            <div class=" hidden lg:flex " v-if="store.role.length > 0" style="width: 200px;" id="mobile-nav">

                <Sidebar v-model="store"></Sidebar>
            </div>
        </template>
        <div :class="'px-2 ' + (isLogin ? '' : 'lg:ml-[200px]')" v-if="store.role.length > 0">

            <!-- <Limit v-if="store.limit.exceeded==true" v-model="store.limit"></Limit> -->
            <!-- <Onboarding v-if="!isLogin && nameUpdated" @nameSaved="nameSaved"></Onboarding> -->
          
            <Onboarding v-model="accountDetails" v-if="nameRequired" @nameSaved="nameSaved">
            </Onboarding>

            <router-view :key="$route.fullPath"></router-view>

        </div>
    </div>
</template>
<script>
import Help from '@/components/Help.vue';
import Sidebar from '@/components/Sidebar.vue';
import Payment from '@/components/Payment.vue'
import TopNav from '@/components/TopNav.vue';
import Header from '@/components/Header.vue'
import { userStore } from "../stores/User"
import { registerStore } from '@/stores/Register';
import { qrStore } from '@/stores/QR';
import { templateStore } from '@/stores/Template';
import { foldersStore } from '@/stores/Folders'
import router from '../router'
import Onboarding from '@/components/user/Onboarding.vue';
import Limit from '@/components/Limit.vue';

export default {
    name: "AppLayout",
    setup() {
        const registerStoreIs = registerStore()
        const userStoreIs = userStore();
        const qrStoreIs = qrStore();
        const templateStoreIs = templateStore();
        const foldersStoreIs = foldersStore();
        return {
            store: userStoreIs,
            rStore: registerStoreIs,
            qStore: qrStoreIs,
            tStore: templateStoreIs,
            fStore: foldersStoreIs
        }
    },
    data() {
        return {
            paymentFailed: false,
            accountDetails: {},
            nameRequired: true

        };
    },

    watch: {
        $route(to, from) {
            //check limit
            // this.store.checkMetered(to).then(() => {
            let type = to.meta.type
            this.store.showSubAccount = (type === 'folders') ? false : true
            if (to.path === '/codes' || to.path === '/templates') {
                this.qStore.$reset();
                this.tStore.$reset();
            }
            if (!to.matched.some(record => record.meta.public)) {
                let userDetails = localStorage.user_details;
                if (userDetails != null) {
                    let currentUser = JSON.parse(userDetails);
                    this.store.firebaseUser = currentUser
                    this.aColor = this.store.avatarDetails[0]
                    this.nameInitials = this.store.avatarDetails[1]
                    if (currentUser.account.account.payment == null && this.$route.name != 'success') {
                        router.push("/subscribe")
                    } else {
                        this.paymentFailed = true
                    }
                }
                else {
                    router.push("/login")
                }

            }

            // })

        }
    },
    mounted() {
        this.checkAccount()
    },
    computed: {
        limit() {
            return this.store.limit
        },
        isLogin() {
            console.log(this.$route.name);
            return (this.$route.name === 'login' || this.$route.name === 'validate' || this.$route.name === 'create-contents' || this.$route.name === 'subscribe' || this.$route.name === 'success' || this.$route.name === 'success' || this.$route.name === 'change-account')
        },
      

    },
    created() {

        let userDetails = localStorage.user_details;
        if (userDetails != null) {
            let userDetailsJson = JSON.parse(userDetails);
            let account = userDetailsJson.account;
            this.store.fetchAccount({ firebaseId: userDetailsJson.uid }).then(async (accountIs) => {
                if (accountIs != null) {
                    account = accountIs;
                    userDetailsJson.account = accountIs;
                    if (account != null && account.account != null) {
                        this.store.role = account.account.type
                    }
                    localStorage.user_details = JSON.stringify(userDetailsJson);
                    //this.checkPayment(accountIs)
                    // let subAccounts= await this.fStore.loadFolders();
                    // let sA= []
                    // subAccounts.forEach(sub => {
                    //     sA.push({id:sub.id,name:sub.name})
                    // });
                    // sA.sort();
                    // localStorage.subAccounts = JSON.stringify(sA);

                }
            })
        }
    },

    methods: {
        checkAccount() {
            let accountName = ""
            let userName= ""
            if (window.localStorage.user_details != null) {
                let user = JSON.parse(window.localStorage.user_details);
                accountName = user?.account?.account?.accountName
                userName= user?.displayName
            }
            this.accountDetails = { userName: userName?userName:"", currentAccountName: accountName ? accountName : "" };
            this.nameRequired = (this.accountDetails .userName.length == 0 || this.accountDetails .currentAccountName?.length == 0)
        },
        sidebarHandler() {
            var sideBar = document.getElementById("mobile-nav");
            sideBar.classList.toggle("hidden");
        },
        leftNavOptions() {
            let nav = this.store.getNavigationOptions();
            return nav;
        },
        nameSaved() {
            // let userDetails = localStorage.user_details;
            // if (userDetails != null) {
            //     let account = JSON.parse(userDetails).account;
            //     if (account != null && account.account != null) {
            //         this.store.role = account.account.type
            //     }
            // }
            this.nameRequired = false;
        }
        // checkPayment(account) {
        //     if (account.account.payment == null) {
        //         this.paymentRequired = true
        //     }
        // }
    },
    components: { TopNav, Sidebar, Help, Header, Onboarding, Payment, Limit }
};
</script>
