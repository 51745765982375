<template>
    <div>
        <nav
            class="h-16 w-full flex items-center lg:items-stretch justify-end lg:justify-between bg-white border-gray-100 border-b-2 relative z-10">

            <div class="flex w-full pr-6">
                <div class="w-full m:w-1/2 h-full flex items-center ">
                    <div class="flex flex-nowrap items-center justify-center">
                        <span class="ml-5 p-2 px-3 mr-1" :style="'background-color:' + accountIcon.bgcolor">{{
                            accountIcon.initials
                            }}</span>
                        <h2 class=" text-[36px] font-bold text-orange-600"> {{ accountName }}</h2>
                    </div>
                    <!-- <div class="flex items-center">
                        <img src="../assets/unitedqr_logo.png" class="h-14" />
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="icon lg:hidden  icon-tabler    icon-tabler-menu-2 cursor-pointer " width="20"
                            height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#718096" fill="none"
                            stroke-linecap="round" stroke-linejoin="round" @click="sidebarHandler()">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="4" y1="6" x2="20" y2="6" />
                            <line x1="4" y1="12" x2="20" y2="12" />
                            <line x1="4" y1="18" x2="20" y2="18" />
                        </svg>
                    </div> -->
                </div>
                <div class=" sm:w-1/2 hidden lg:flex">
                    <div class="w-full flex items-center pl-8 justify-end">
                        <div class="mr-4 flex justify-center ">
                            <router-link :to="'/change-account'"
                                class=" ml-4 cursor-pointer focus:outline-none border   transition duration-150 ease-in-out hover:bg-gray-200  p-2 rounded flex items-center justify-center">Change
                                Account
                            </router-link>
                        </div>
                        <div class="h-full w-20 flex items-center justify-center border-r border-l mr-4">
                            <div class="relative cursor-pointer text-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler  icon-tabler-bell"
                                    width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z"></path>
                                    <path
                                        d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6">
                                    </path>
                                    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                                </svg>
                                <div
                                    class="w-2 h-2 rounded-full bg-red-400 border border-white absolute inset-0 mt-1 mr-1 m-auto">
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center relative cursor-pointer" @click="dropdownHandler($event)">
                            <div class="rounded-full">
                                <ul
                                    class="p-2 w-full border-r bg-white absolute rounded left-0 shadow mt-12 sm:mt-16 hidden">
                                    <!-- <li
                                        class="flex w-full justify-between text-gray-600 hover:text-indigo-700 cursor-pointer items-center">
                                        <div class="flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-user" width="18" height="18"
                                                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <circle cx="12" cy="7" r="4" />
                                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                            </svg>
                                            <span class="text-sm ml-2">My Profile</span>
                                        </div>
                                    </li> -->
                                    <li
                                        class="flex w-full justify-between text-gray-600 hover:text-indigo-700 cursor-pointer items-center mt-2">
                                        <a @click="logOutUser" class="flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-logout" width="20" height="20"
                                                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <path
                                                    d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                                <path d="M7 12h14l-3 -3m0 6l3 -3" />
                                            </svg>
                                            <span class="text-sm ml-2">Sign out</span>
                                        </a>
                                    </li>
                                </ul>
                                <div class="relative">
                                    <div :style="{ 'background-color': avatarColor }"
                                        class="shadow-sm  w-10 h-10 rounded-full items-center flex">
                                        <span class="w-full text-center text-sm ">{{ initials }}</span>
                                    </div>
                                    <div
                                        class="w-2 h-2 rounded-full bg-green-400 border border-white absolute inset-0 mb-0 mr-0 m-auto">
                                    </div>
                                </div>
                            </div>
                            <p class="text-gray-800 text-sm mx-3">{{ userName }}</p>
                            <div class="cursor-pointer text-gray-600">
                                <svg aria-haspopup="true" xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-chevron-down" width="20" height="20"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-gray-600 mr-8 visible lg:hidden relative" @click="dropdownHandler($event)">
                <ul class="p-2 w-40 border-r bg-white absolute rounded right-0 shadow mt-12 hidden">
                    <!-- <li
                        class="flex w-full justify-between text-gray-600 hover:text-indigo-700 cursor-pointer items-center">
                        <div class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="18"
                                height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                            <span class="text-sm ml-2">My Profile</span>
                        </div>
                    </li> -->
                    <li
                        class="flex w-full justify-between text-gray-600 hover:text-indigo-700 cursor-pointer items-center mt-2">
                        <a @click="logOutUser" class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout"
                                width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                <path d="M7 12h14l-3 -3m0 6l3 -3" />
                            </svg>
                            <span class="text-sm ml-2">Sign out</span>
                        </a>
                    </li>
                </ul>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            </div>
        </nav>
    </div>
</template>
<script>
import { registerStore } from '@/stores/Register';
import router from '../router'
export default {
    name: 'AppTopNav',
    setup() {
        const registerStoreIs = registerStore()

        return {
            rStore: registerStoreIs
        }
    },
    props: {
        value: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {

        };
    },
    computed: {
        userName() {
            return this.value.firebaseUser.displayName;
        },
        avatarColor() {
            return this.value.avatarDetails[0];
        },
        initials() {
            return this.value.avatarDetails[1];
        },
        accountIcon() {

            let sub = window.localStorage.sub_account;
            if (sub) {
                let sub_account = JSON.parse(sub)
                return this.iconCalculation(sub_account.name)
            }  
            else {
                if (window.localStorage.user_details != null) {
                    let user_details = JSON.parse(window.localStorage.user_details);
                    if( user_details?.account?.account?.accountName){
                    return this.iconCalculation(user_details?.account?.account?.accountName)
                    }
                    else{
                        return ""
                    }

                }
            }

        },
        accountName() {
            let sub_account = window.localStorage.sub_account;
            if (sub_account) {
                let userJson = JSON.parse(sub_account)

                return userJson.name
            }
            else {
                if (window.localStorage.user_details != null) {
                    let user_details = JSON.parse(window.localStorage.user_details);
                    return user_details?.account?.account?.accountName
                }
            }
        }

    },
    mounted() {

    },

    methods: {
        iconCalculation(sub_account) {
            var hash = 0;
            for (var i = 0; i < sub_account.length; i++) {
                hash = sub_account.charCodeAt(i) + ((hash << 5) - hash);
            }
            var h = hash % 360;
            let bgcolor = 'hsl(' + h + ', 30%, 80%)';
            let initialsArray = sub_account.split(" ");
            let initials = initialsArray[0].slice(0, 1);
            if (initialsArray.length > 1) {
                initials = initials + initialsArray[1].slice(0, 1)
            }
            return { bgcolor: bgcolor, initials: initials }
        },

        dropdownHandler(event) {
            let single = event.currentTarget.getElementsByTagName("ul")[0];
            single.classList.toggle("hidden");
        },
        sidebarHandler() {
            this.$emit("sidebarHandler")
        },
        logOutUser() {
            this.rStore.logoutUser().then(() => {
                router.push("/login")
            })
        }
    },
};
</script>